import React from 'react';
import ReactGA from 'react-ga4';
import CacheBuster from 'react-cache-buster';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Toaster } from 'react-hot-toast-promise';
import Redirector from './pages/Redirector';
import Layout from './pages/Layout';
import RequireAuth from './pages/RequireAuth';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Dashboard from './pages/Authenticated/Dashboard';
import ViewProject from './pages/Authenticated/OrderList/ViewProject';
import MyProjects from './pages/Authenticated/OrderList/MyProjects';
import { FinancialDashboard, Register, Entities, Seamstresses } from './pages/Authenticated/Financial';
import ContextProviders from './contexts';
import MyClothingModels from './pages/Authenticated/Clothing/MyClothingModels';
import IconCatalog from './pages/Authenticated/Clothing/IconCatalog';
import ClothingPresets from './pages/Authenticated/Clothing/ClothingPresets';
import OrderSettings from './pages/Authenticated/OrderList/OrderSettings';
import LoadingSystem from './pages/Authenticated/LoadingSystem';
import ProjectReport from './pages/Authenticated/OrderList/ProjectReport';
import ViewProjectClient from './pages/NoAuth/ViewProjectClient';
import Spinner from './components/Spinner';
import { isDev } from './utils/helper';
import { GlobalStyle } from './theme/globalStyle';
import packageJsonFile from '../package.json';
import { useAppTranslation } from './contexts/TranslationContext';
import ProjectsSchedule from './pages/Authenticated/OrderList/ProjectsSchedule';
import KanbanView from './pages/Authenticated/KanbanView';
import KanbanWorkspaces from './pages/Authenticated/KanbanWorkspaces';
import { Kanban } from './pages/Authenticated/Kanban';
import { ToastContainer } from 'react-toastify';
import Invoice from './pages/Authenticated/Invoice';
import { DashboardSurvey } from './pages/Authenticated/OrderList/DashboardSurvey';
import SellsReport from './pages/Authenticated/Financial/SellsReport';

if (!isDev()) {
  const TRACKING_ID = 'G-40SLDFS90M';
  ReactGA.initialize(TRACKING_ID);
}

const router = createBrowserRouter([
  { path: '/', element: <Redirector /> },
  { path: 'login', element: <Login /> },
  { path: 'editor', element: <ViewProjectClient /> },
  { path: 'order/invoice/:uuid', element: <Invoice /> },
  {
    element: <Layout />,
    children: [
      {
        element: <RequireAuth />,
        children: [
          { path: 'loading', element: <LoadingSystem /> },
          { path: 'dashboard', element: <Dashboard /> },
          { path: 'orderlist/projects', element: <MyProjects /> },
          { path: 'orderlist/projects/schedule', element: <ProjectsSchedule /> },
          { path: 'orderlist/projects/view', element: <ViewProject /> },
          { path: 'orderlist/projects/view/report', element: <ProjectReport /> },
          { path: 'orderlist/settings', element: <OrderSettings /> },
          { path: 'orderlist/survey', element: <DashboardSurvey /> },
          { path: 'clothes/clothing-models', element: <MyClothingModels /> },
          { path: 'clothes/clothing-presets', element: <ClothingPresets /> },
          { path: 'clothes/clothing-icons', element: <IconCatalog /> },
          { path: 'financial/dashboard', element: <FinancialDashboard /> },
          { path: 'financial/register', element: <Register /> },
          { path: 'financial/sells-report', element: <SellsReport /> },
          { path: 'financial/entities', element: <Entities /> },
          { path: 'financial/seamstress', element: <Seamstresses /> },
          {
            element: <Kanban />,
            children: [
              { path: 'kanban/workspaces', element: <KanbanWorkspaces /> },
              { path: 'kanban/workspaces/view', element: <KanbanView /> }
            ]
          }
        ]
      }
    ]
  },
  { path: '*', element: <NotFound /> }
]);

const CacheBusterLoading = () => {
  const { Translate } = useAppTranslation();

  return (
    <div className='d-flex justify-content-center align-items-center vh-100'>
      <Spinner />
      <h4 className='m-0 ml-3'>{Translate('progress.checking-updates')}</h4>
    </div>
  );
};

function App() {
  return (
    <ContextProviders>
      <CacheBuster currentVersion={packageJsonFile.version} isEnabled={!isDev()} loadingComponent={<CacheBusterLoading />}>
        <GlobalStyle />
        <Toaster position='top-center' />
        <ToastContainer />
        <RouterProvider router={router} />
      </CacheBuster>
    </ContextProviders>
  );
}

export default App;
