import React, { useCallback, useState } from 'react';
import ModalBase, { type ModalBaseType } from '../ModalBase';
import ImagePickerBase64 from '../../ImagePickerBase64';
import { useUser } from '../../../contexts/UserContext';
import { toast } from 'react-hot-toast-promise';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import SpinnerWithMessage from '../../SpinnerWithMessage';
import { getServerErrorMessageFromResponse } from '../../../utils/helper';

type CompanyLogoPickerModalType = Pick<ModalBaseType, 'handleClose'>;

export default function CompanyLogoPickerModal({ handleClose }: CompanyLogoPickerModalType) {
  const [isUploading, setIsUploading] = useState(false);
  const [croppedImage, setCroppedImage] = useState('');

  const { addLogoToUser } = useUser();
  const { Translate } = useAppTranslation();

  const handleConfirm = useCallback(async () => {
    try {
      setIsUploading(true);
      await addLogoToUser(croppedImage);
      handleClose();
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    } finally {
      setIsUploading(false);
    }
  }, [addLogoToUser, croppedImage, handleClose]);

  return (
    <ModalBase
      isLarge
      disableClickOutsideToClose
      visible={true}
      style='primary'
      title={Translate('labels.logo')}
      message=''
      confirmationButtonDisabled={false}
      handleConfirm={handleConfirm}
      handleClose={handleClose}
      hideFooter={isUploading}
    >
      <>
        {!isUploading && (
          <div className='row'>
            <div className='col'>
              <ImagePickerBase64
                useSquareAspectRatioCrop
                label={Translate('description.logo-image-resize-info')}
                resizeSettings={{ width: 256, height: 256 }}
                maxImageHeightInEditor={400}
                onSelect={response => {
                  setCroppedImage(response);
                }}
              />
            </div>
            <div className='col d-flex justify-content-center align-items-center'>
              <img src={croppedImage} />
            </div>
          </div>
        )}

        {isUploading && <SpinnerWithMessage message={Translate('progress.sending')} />}
      </>
    </ModalBase>
  );
}
