import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BasicButton from '../../Buttons/BasicButton';
import { Overlay } from '../styles';
import { type SublistType } from '../../../types/SublistTypes';
import { useAppTranslation } from '../../../contexts/TranslationContext';

type SublistPickerModalType = {
  title: string;
  message: string;
  visible: boolean;
  style: 'primary' | 'danger';
  sublists: SublistType[];
  handleClose: () => void;
  handleConfirm: (sublistId: number) => void;
};

export default function SublistPickerModal({
  title,
  message,
  visible,
  style,
  sublists,
  handleClose,
  handleConfirm
}: SublistPickerModalType) {
  const { Translate } = useAppTranslation();

  const [selectedSublistId, setSelectedSublistId] = useState<number>(-1);

  const confirmationButtonRef = useRef<HTMLButtonElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const handleCheckCloseAction = useCallback((event: React.MouseEvent) => {
    if (event.target === modalRef.current) {
      handleClose();
    }
  }, [handleClose]);

  const show = useMemo(() => (visible ? 'show' : ''), [visible]);

  const handleCloseByKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (visible) confirmationButtonRef.current?.focus();
  }, [visible]);

  useEffect(() => {
    document.addEventListener('keydown', handleCloseByKeyPress);

    return () => {
      document.removeEventListener('keydown', handleCloseByKeyPress);
    };
  }, [handleCloseByKeyPress]);

  if (!visible) return <></>;

  return (
    <Overlay id='modal-overlay' className={`fade ${show}`}>
      {visible && (
        <div ref={modalRef} className='modal' style={{ display: 'block' }} onClick={handleCheckCloseAction}>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{title}</h5>
                <button type='button' className='close' onClick={handleClose}>
                  <span>&times;</span>
                </button>
              </div>
              <div className='modal-body'>
                <p>{message}</p>

                <div className='form-group'>
                  <select
                    className='form-control'
                    onChange={({ target }) => {
                      setSelectedSublistId(parseInt(target.value));
                    }}
                    value={selectedSublistId}
                  >
                    {sublists.map((sublist, index) => (
                      <option key={index} value={sublist.id}>
                        {sublist.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='modal-footer'>
                <BasicButton title={Translate('actions.close')} color='light' handleClick={handleClose} />
                <BasicButton
                  disabled={selectedSublistId === -1}
                  title={Translate('actions.confirm')}
                  color={style}
                  buttonRef={confirmationButtonRef}
                  handleClick={() => {
                    if (selectedSublistId !== -1) handleConfirm(selectedSublistId);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Overlay>
  );
}
