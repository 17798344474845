import { type TFunction } from 'i18next';
import { type ProjectBasicType } from '../../../../../types/ProjectBasicType';

type GetProjectStatusFromFinalClientViewResponse = {
  style: 'success' | 'primary' | 'warning' | 'default';
  text: string;
}

export const getProjectStatusFromFinalClientView = (project: ProjectBasicType, Translate: TFunction): GetProjectStatusFromFinalClientViewResponse => {
  if (project.approved_at) return { style: 'success', text: Translate('status.approved') };

  if (!project.token || project.final_client_link_offline) return { style: 'default', text: Translate('status.no-access') };

  if (!!project.token && project.final_client_readonly) return { style: 'warning', text: Translate('status.can-see') };

  if (!!project.token && !project.final_client_readonly) return { style: 'primary', text: Translate('status.can-edit') };

  return { style: 'default', text: '-' };
};

export const updateCachedProject = (projectToUpdate: ProjectBasicType, cachedProjects: ProjectBasicType[]): ProjectBasicType[] => {
  const updatedCachedProjects = cachedProjects.map(project => {
    if (project.id === projectToUpdate.id) return projectToUpdate;
    return project;
  });

  return updatedCachedProjects;
};
