import _ from 'lodash';
import { useFormContext } from 'react-hook-form';

import { Container, ImageFile } from '../../../style';
import { WrapperFile, RowMaterial, WrapperRawMaterial, WrapperForm, Content } from '../styles';
import { generatePathRegisterFile } from '../../../../../../../../../helpers/general';

type Errors = {
  photo?: { message: string };
  title?: { message: string };
  description?: { message: string };
  type_meter?: { message: string };
  color?: { message: string };
};

type ProductsError = {
  products?: Record<number, Errors>;
};

type RawMaterialContentProps = {
  handleInventory: (rawMaterial: any) => void;
};

export function RawMaterialContent({ handleInventory }: RawMaterialContentProps) {
  const methods = useFormContext();

  const key = 'products';
  const rawMaterialValue = methods.watch(key) ?? [];
  const errors = methods.formState.errors as ProductsError;

  return (
    <Container>
      <WrapperRawMaterial>
        {rawMaterialValue?.map((rawMaterial: any, index: number) => (
          <RowMaterial key={index}>
            <Content>
              <div>
                <WrapperFile>
                  {!_.isEmpty(rawMaterial.photo) ? (
                    <ImageFile
                      src={
                        typeof rawMaterial.photo === 'string'
                          ? generatePathRegisterFile({ filename: rawMaterial.photo })
                          : URL.createObjectURL(rawMaterial.photo[0])
                      }
                      alt='Imagem do cliente'
                    />
                  ) : (
                    <span>Adicionar foto</span>
                  )}

                  <input
                    type='file'
                    onChange={e => {
                      const file = e.target.files?.[0];
                      if (file) {
                        methods.setValue(`${key}.${index}.photo`, [file]);
                      }
                    }}
                  />
                </WrapperFile>
                {errors.products?.[index]?.photo && (
                  <div className='alert alert-danger mt-2'>{errors?.products?.[index]?.photo?.message}</div>
                )}
              </div>

              <WrapperForm>
                <div>
                  <label className='form-label'>Nome</label>
                  <input
                    className={`form-control ${errors.products?.[index]?.title ? 'is-invalid' : ''}`}
                    type='text'
                    placeholder='Nome da matéria-prima'
                    value={rawMaterial.title}
                    onChange={e => {
                      methods.setValue(`${key}.${index}.title`, e.target.value);
                    }}
                  />
                </div>

                <div className='row'>
                  <div className='col-8'>
                    <label className='form-label'>Descrição</label>
                    <input
                      className={`form-control ${errors.products?.[index]?.description ? 'is-invalid' : ''}`}
                      type='text'
                      placeholder='Descrição da matéria-prima'
                      value={rawMaterial.description}
                      onChange={e => {
                        methods.setValue(`${key}.${index}.description`, e.target.value);
                      }}
                    />
                  </div>

                  <div className='col-4'>
                    <label className='form-label'>Cor</label>
                    <input
                      className={`form-control ${errors.products?.[index]?.color ? 'is-invalid' : ''}`}
                      type='text'
                      placeholder='Cor da matéria-prima'
                      value={rawMaterial.color}
                      onChange={e => {
                        methods.setValue(`${key}.${index}.color`, e.target.value);
                      }}
                    />
                  </div>
                </div>
              </WrapperForm>
            </Content>

            <div className='d-flex justify-content-end mt-3'>
              <div className='d-flex justify-content-end'>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => {
                    handleInventory({ ...rawMaterial, index });
                  }}
                >
                  Adicionar estoque
                </button>
              </div>

              {_.isEmpty(rawMaterial.list_clothing_model_and_product) ? (
                <button
                  className='btn btn-danger ml-3'
                  type='button'
                  onClick={() => {
                    methods.setValue(
                      key,
                      rawMaterialValue.filter((_: any, i: number) => i !== index)
                    );
                  }}
                >
                  <i className='fa fa-trash mr-2'></i>
                  Remover
                </button>
              ) : null}
            </div>
          </RowMaterial>
        ))}
      </WrapperRawMaterial>

      <button
        className='btn btn-warning mt-3'
        type='button'
        onClick={() => {
          methods.setValue(key, [...rawMaterialValue, { photo: null }]);
        }}
      >
        <i className='fa fa-plus mr-2'></i>
        Adicionar matéria-prima
      </button>
    </Container>
  );
}
