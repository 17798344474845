import IntlCurrencyInput from 'react-intl-currency-input';
import { useFormContext } from 'react-hook-form';

import { Container, RowModels, WrapperIconAndName, WrapperInput } from './style';
import { useClothingModels } from '../../../../../../../../contexts/ClothingModelsContext';

export function Fraccao() {
  const { models } = useClothingModels();
  const methods = useFormContext();

  const formModels = methods.watch('factions') || [];

  return (
    <div>
      <h4 className='h6'>Valor por Modelagem</h4>

      <Container>
        {models?.map((model, index) => {
          return (
            <RowModels key={model.id}>
              <WrapperIconAndName>
                <img src={model.icon.url} alt='' srcSet='' />
                <span>{model.name}</span>
              </WrapperIconAndName>

              <div>
                <label htmlFor='price'>Preço</label>
                <WrapperInput className={'form-control'}>
                  <IntlCurrencyInput
                    currency='BRL'
                    config={{
                      locale: 'pt-BR',
                      formats: {
                        number: {
                          BRL: {
                            style: 'currency',
                            currency: 'BRL',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }
                        }
                      }
                    }}
                    defaultValue={0}
                    max={0}
                    value={formModels[index]?.price}
                    onChange={(_, value) => {
                      methods.setValue(`factions.${index}`, { id: model.id, price: value });
                    }}
                  />
                </WrapperInput>
              </div>
            </RowModels>
          );
        })}
      </Container>
    </div>
  );
}
