import React, { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TourProvider, useTour } from '@reactour/tour';
import CircleButton from '../../../components/Buttons/CircleButton';
import { useHeaderBackButton } from '../../../contexts/HeaderBackButtonContext';
import { IconsCatalog } from '../../../components/IconsCatalog';
import { useLocation, useNavigate } from 'react-router';
import { useTheme } from '../../../contexts/ThemeContext';
import { type Order } from '../../../types/OrderTypes';
import { type SublistType } from '../../../types/SublistTypes';
import { type ClothingGenderType, type ClothingModelType } from '../../../types/ClothingModelType';
import { generateInitialOrder } from '../../Authenticated/OrderList/ViewProject/helper/orderHelper';
import { useProjects } from '../../../contexts/ProjectsContext';
import { toast } from 'react-hot-toast-promise';
import Spinner from '../../../components/Spinner';
import { type FinalClientEditorSettings } from '../../../types/FinalClientEditor/FinalClientEditorSettings';
import MainFormEditor from '../../Authenticated/OrderList/ViewProject/modules/MainFormEditor';
import CollapsableCard from '../../../components/Cards/CollapsableCard';
import { generateProjectReport } from '../../Authenticated/OrderList/ViewProject/services/projectReportService';
import { type ProjectReportType } from '../../../types/ProjectReportType';
import { areObjectsEquals } from '../../Authenticated/OrderList/ViewProject/services/objectComparation';
import { type ProjectAccessInfo } from '../../../types/FinalClientEditor/ProjectAccessInfo';
import ConfirmSaveFinalClientChangesModal, {
  type SignatureResponseType
} from '../../../components/Modals/ConfirmSaveFinalClientChangesModal';
import SplitButton from '../../../components/Buttons/SplitButton';
import { useAppTranslation } from '../../../contexts/TranslationContext';
import {
  type SatisfactionSurveyType,
  type ProjectDataFinalClientEditor
} from '../../../types/FinalClientEditor/ProjectDataFinalClientEditor';
import SyncStatusBar from '../../../components/SyncStatusBar';
import { shouldShowTourGuide, disableTourGuideShowAgain } from './services/tourGuideService';
import Footer from '../../../components/Footer';
import { HeaderRightSection } from '../../Authenticated/OrderList/ProjectReport/components/ProjectReportHeader/styles';
import { displayFormattedDateWithAbbreviatedMonth } from '../../../helpers/dateTime';
import MainFormEditorModal from '../../Authenticated/OrderList/ViewProject/components/MainFormEditorModal';
import { StatusProject } from './fragments/StatusProject';
import { SurveyEvaluation } from './fragments/SurveyEvaluation';
import ComboBox from '../../../components/Forms/ComboBox';
import {
  checkAllModelsHaveEmptyTableSizes,
  generateComboboxOptionsFromImportedModels
} from '../../Authenticated/OrderList/ViewProject/services/modelService';
import ClothingMetricsTable from '../../../components/Tables/ClothingMetricsTable';
import { type TableSizeConfigType } from '../../../types/TableSizeConfigType';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getServerErrorMessageFromResponse } from '../../../utils/helper';
import TrackingNumber from './fragments/TrackingNumber';
import { type ProjectShippingType } from '../../../types/ProjectShippingType';
import { addOrder, editOrder } from '../../Authenticated/OrderList/services/orderService';

const TableOrderManager = lazy(
  async () => await import('../../Authenticated/OrderList/ViewProject/components/TableOrderManager')
);

function Render() {
  const sublistsContainerRef = useRef<HTMLDivElement>(null);

  const { Translate, dateFnsLocale } = useAppTranslation();
  const { setIsOpen, setSteps } = useTour();

  const [companyData, setCompanyData] = useState<ProjectDataFinalClientEditor['company']>({
    name: '',
    logo: null,
    list_preferences: undefined
  });

  const [sublists, setSublists] = useState<SublistType[]>([]);
  const [projectShipping, setProjectShipping] = useState<ProjectShippingType | null>(null);
  const [targetSublistIndex, setTargetSublistIndex] = useState<number>(0);
  const [importedModels, setImportedModels] = useState<ClothingModelType[]>([]);
  const [editorSettings, setEditorSettings] = useState<FinalClientEditorSettings>({} as FinalClientEditorSettings);
  const [order, setOrder] = useState<Order>({} as Order);
  const [showTourGuide] = useState(shouldShowTourGuide());
  const [projectReport, setProjectReport] = useState<ProjectReportType | undefined>(undefined);
  const [projectAccessInfo, setProjectAccessInfo] = useState<ProjectAccessInfo>({} as ProjectAccessInfo);
  const [clientName, setClientName] = useState<string | null>(null);
  const [deliveryDate, setDeliveryDate] = useState<string | null>(null);
  const [page, setPage] = useState<'pedido' | 'status' | 'avaliacao' | 'tracking'>('pedido');
  const [clothingTableSizeConfig, setClothingTableSizeConfig] = useState<TableSizeConfigType | null>(null);
  const [satisfactionSurvey, setSatisfactionSurvey] = useState<SatisfactionSurveyType | null>({} as SatisfactionSurveyType);

  const [isSynched, setIsSynched] = useState(true);
  const [lastSynchedSublists, setLastSynchedSublists] = useState<SublistType[]>([]);

  const isEditingOrder = useMemo(() => !!order.id, [order]);
  const hideTableSizesForModels = useMemo(() => checkAllModelsHaveEmptyTableSizes(importedModels), [importedModels]);

  const [loadingProject, setLoadingProject] = useState(true);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { isDark, toggleTheme } = useTheme();
  const { backButtonVisible } = useHeaderBackButton();

  const { loadProjectForAnonymousUser, updateProjectWithFinalClientChanges, finishFinalClientEditings } = useProjects();

  const { search } = useLocation();

  const navigate = useNavigate();

  const tourSteps = useMemo(
    () => [
      {
        selector: '[data-tour="step-1"]',
        content: Translate('tourguide.final-client-step-1')
      },
      {
        selector: '[data-tour="step-2"]',
        content: Translate('tourguide.final-client-step-2')
      },
      {
        selector: '[data-tour="step-3"]',
        content: Translate('tourguide.final-client-step-3')
      }
    ],
    [Translate]
  );

  const handleSaveFinalClientChanges = useCallback(async (): Promise<void> => {
    try {
      const { projectId, accessToken } = projectAccessInfo;

      await updateProjectWithFinalClientChanges(projectId, accessToken, sublists);
      setLastSynchedSublists(sublists);
      setIsSynched(true);
    } catch (err) {
      toast.error(getServerErrorMessageFromResponse(err));
    }
  }, [projectAccessInfo, updateProjectWithFinalClientChanges, sublists]);

  const finalClientCompletedTheList = useCallback(
    async ({ clientNameAccepting, signatureImageData }: SignatureResponseType) => {
      try {
        const { projectId, accessToken } = projectAccessInfo;

        await finishFinalClientEditings({ projectId, accessToken, clientNameAccepting, signatureImageData });

        setEditorSettings({ ...editorSettings, final_client_readonly: true });
      } catch (err) {
        toast.error(getServerErrorMessageFromResponse(err));
      }
    },
    [editorSettings, finishFinalClientEditings, projectAccessInfo]
  );

  const runFinalClientCompletedTheListWithToastPromise = useCallback(
    ({ clientNameAccepting, signatureImageData }: SignatureResponseType) => {
      const promise = new Promise<void>((resolve, reject) => {
        const forcedSync = async () => {
          if (!isSynched) await handleSaveFinalClientChanges();
          await finalClientCompletedTheList({ clientNameAccepting, signatureImageData });
        };

        forcedSync()
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      });

      toast.promise(promise, {
        loading: Translate('progress.loading'),
        success: Translate('toast.company-received-you-changes'),
        error: getServerErrorMessageFromResponse
      });
    },
    [Translate, finalClientCompletedTheList, handleSaveFinalClientChanges, isSynched]
  );

  const updateSublistsWithoutFireGlobalUpdate = useCallback((updatedSublists: SublistType[]) => {
    setLastSynchedSublists(updatedSublists);
    setSublists(updatedSublists);
  }, []);

  const handleOnAddOrder = useCallback((order: Order) => {
    const updatedSublists = addOrder(sublists, targetSublistIndex, order);
    updateSublistsWithoutFireGlobalUpdate(updatedSublists);
  }, [sublists, targetSublistIndex, updateSublistsWithoutFireGlobalUpdate]);

  /**
   * Happens after the project is edited in backend.
   */
  const handleOnEditOrder = useCallback((order: Order) => {
    const updatedSublists = editOrder({ order, sublists });
    updateSublistsWithoutFireGlobalUpdate(updatedSublists);
  }, [sublists, updateSublistsWithoutFireGlobalUpdate]);

  const pageCenter = useMemo(() => {
    const pageComponents = {
      pedido: (
        <TableOrderManager
          startWithClosedPanel
          isSynching={!isSynched}
          setOrder={setOrder}
          importedModels={importedModels}
          isEditingOrder={isEditingOrder}
          sublists={sublists}
          setSublists={setSublists}
          sublistsContainerRef={sublistsContainerRef}
          screenshotMode={false}
          isCompanyEditor={false}
          hideExclusiveModelPricingTab={true}
          editorSettings={editorSettings}
          projectReport={projectReport}
          preferences={companyData.list_preferences}
          projectId={projectAccessInfo.projectId}
          onUpdateSublists={updateSublistsWithoutFireGlobalUpdate}
        />
      ),
      status: <StatusProject />,
      avaliacao: (
        <SurveyEvaluation
          name={companyData.name}
          userId={companyData.list_preferences?.user_id ?? 0}
          projectAccessInfo={projectAccessInfo}
          satisfactionSurvey={satisfactionSurvey as SatisfactionSurveyType}
        />
      ),
      tracking: <TrackingNumber shipping={projectShipping} />
    };

    return pageComponents[page] || pageComponents.pedido;
  }, [isSynched, importedModels, isEditingOrder, sublists, editorSettings, projectReport, companyData.list_preferences, companyData.name, projectAccessInfo, updateSublistsWithoutFireGlobalUpdate, satisfactionSurvey, projectShipping, page]);

  /** RECALCULATE REPORT WHEN SUBLISTS CHANGES */
  useEffect(() => {
    if (!projectReport?.ready) return;
    const recalculatedProjectReport = generateProjectReport({
      sublists,
      importedModels,
      shipping: null,
      Translate
    });
    const reportChanged = !areObjectsEquals(projectReport, recalculatedProjectReport);

    if (!reportChanged) return;
    setProjectReport(recalculatedProjectReport);
  }, [sublists, importedModels, projectReport, Translate]);

  /** CHECK SYNC STATUS */
  useEffect(() => {
    const noChanges = areObjectsEquals(sublists, lastSynchedSublists);

    if (noChanges) {
      setIsSynched(true);
      return;
    }

    setIsSynched(false);
    handleSaveFinalClientChanges();
  }, [handleSaveFinalClientChanges, lastSynchedSublists, sublists]);

  useEffect(() => {
    const params = new URLSearchParams(search);

    if (!params.has('p') || !params.has('t')) {
      setFailedToLoad(true);
      return;
    }

    setLoadingProject(true);

    const projectId = params.get('p');
    const accessToken = params.get('t');

    loadProjectForAnonymousUser(parseInt(projectId!), accessToken!)
      .then(projectData => {
        const {
          sublists,
          models,
          company,
          final_client_readonly,
          final_client_hide_molds_prices,
          client_name,
          delivery_date,
          shipping,
          uuid,
          satisfaction_survey
        } = projectData;
        setProjectAccessInfo({ projectId: parseInt(projectId!), accessToken: accessToken!, uuid });
        setSublists(sublists);
        setProjectShipping(shipping);
        setImportedModels(models);
        setClothingTableSizeConfig({ selectedGender: 'male', selectedModelId: models[0].id });
        setOrder(generateInitialOrder(models));
        setEditorSettings({ final_client_readonly, final_client_hide_molds_prices });
        setLastSynchedSublists(sublists);
        setCompanyData({ ...company });
        setClientName(client_name);
        setDeliveryDate(delivery_date);
        setSatisfactionSurvey(satisfaction_survey);

        if (!final_client_hide_molds_prices) {
          const projectReport = generateProjectReport({
            sublists,
            importedModels: models,
            shipping: null,
            Translate
          });

          setProjectReport(projectReport);
        }
      })
      .catch(err => {
        toast.error(getServerErrorMessageFromResponse(err));
        setFailedToLoad(true);
      })
      .finally(async () => {
        setLoadingProject(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadingProject || !showTourGuide) return;

    setTimeout(() => {
      if (setSteps) setSteps(tourSteps);
      setIsOpen(true);
    }, 800);
  }, [loadingProject, showTourGuide, setIsOpen, setSteps, tourSteps]);

  return (
    <React.Fragment>
      {!!companyData.list_preferences && (
        <ConfirmSaveFinalClientChangesModal
          preferences={companyData.list_preferences}
          visible={showConfirmationModal}
          handleClose={() => {
            setShowConfirmationModal(false);
          }}
          handleConfirm={runFinalClientCompletedTheListWithToastPromise}
        />
      )}

      <MainFormEditorModal
        order={order}
        setOrder={setOrder}
        sublists={sublists}
        onEditOrder={handleOnEditOrder}
        importedModels={importedModels}
        targetSublistIndex={targetSublistIndex}
        setTargetSublistIndex={setTargetSublistIndex}
        isCompanyEditor={false}
        isInsideModal={true}
        visible={isEditingOrder}
        renderAsStepper
      />

      <nav className='navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow'>
        {backButtonVisible && (
          <CircleButton
            icon={IconsCatalog.arrowLeft}
            color='light'
            handleClick={() => {
              navigate(-1);
            }}
          />
        )}

        <CircleButton
          icon={!isDark ? IconsCatalog.moon : IconsCatalog.sun}
          color='light'
          handleClick={toggleTheme}
          marginLeft={backButtonVisible}
        />

        <ul className='nav ml-3'>
          <li className='nav-item'>
            <button
              type='button'
              className={'btn btn-text' + (page === 'pedido' ? ' btn-active' : '')}
              onClick={() => {
                setPage('pedido');
              }}
            >
              Pedido
            </button>
          </li>

          {/* <li className='nav-item'>
            <button
              type='button'
              className={'btn btn-text' + (page === 'status' ? ' btn-active' : '')}
              onClick={() => {
                setPage('status');
              }}
            >
              Status
            </button>
          </li> */}

          <li className='nav-item'>
            <button
              type='button'
              className={'btn btn-text' + (page === 'avaliacao' ? ' btn-active' : '')}
              onClick={() => {
                setPage('avaliacao');
              }}
            >
              Avaliação
            </button>
          </li>

          <li className='nav-item'>
            <button
              type='button'
              className={'btn btn-text' + (page === 'tracking' ? ' btn-active' : '')}
              onClick={() => {
                setPage('tracking');
              }}
            >
              {Translate('labels.tracking')}
            </button>
          </li>
        </ul>
      </nav>

      {loadingProject && (
        <div className='d-flex align-items-center justify-content-center pt-3'>
          <Spinner /> <strong className='ml-3'>{Translate('progress.loading')}</strong>
        </div>
      )}

      {failedToLoad && (
        <div className='text-center'>
          <h1>{Translate('labels.sorry')}</h1>
          <p>{Translate('error.failed-to-load-project')}</p>
        </div>
      )}

      {!loadingProject && !failedToLoad && (
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-auto'>
              {companyData.logo && (
                <img src={companyData.logo} width={100} height={100} className='img-fluid shadow-sm rounded my-3' />
              )}
            </div>
            <div className='col-auto d-flex align-items-center'>
              <h2>{companyData.name}</h2>
            </div>
            <div className='col d-flex align-items-center justify-content-end'>
              <HeaderRightSection>
                <div className={'row mt-3'}>
                  <div className='col d-flex'>
                    {!!clientName && (
                      <div className='mr-3'>
                        <span>{Translate('labels.client-name')}</span>
                        <h5>{clientName}</h5>
                      </div>
                    )}

                    <section>
                      <span>{Translate('labels.delivery-date')}</span>
                      <h5>
                        {deliveryDate
                          ? displayFormattedDateWithAbbreviatedMonth(deliveryDate, dateFnsLocale)
                          : Translate('status.undefined')}
                      </h5>
                    </section>
                  </div>
                </div>
              </HeaderRightSection>
            </div>
          </div>

          {!hideTableSizesForModels && !!clothingTableSizeConfig && (
            <CollapsableCard
              id='table-clothing-size-metrics'
              header={Translate('labels.measurement-table')}
              startWithClosedPanel
            >
              <div className='row'>
                <div className='col'>
                  <ComboBox
                    id='size-table-model'
                    header={Translate('labels.models')}
                    value={clothingTableSizeConfig.selectedModelId.toString()}
                    data={generateComboboxOptionsFromImportedModels(importedModels)}
                    handleChange={({ target }) => {
                      setClothingTableSizeConfig({ ...clothingTableSizeConfig, selectedModelId: parseInt(target.value) });
                    }}
                  />
                </div>

                <div className='col'>
                  <ComboBox
                    id='table-size-gender-selector'
                    header={Translate('labels.gender')}
                    value={clothingTableSizeConfig.selectedGender}
                    handleChange={({ target }) => {
                      setClothingTableSizeConfig({
                        ...clothingTableSizeConfig,
                        selectedGender: target.value as keyof ClothingGenderType
                      });
                    }}
                    data={[
                      { label: Translate('labels.male'), value: 'male' },
                      { label: Translate('labels.female'), value: 'female' },
                      { label: Translate('labels.childish'), value: 'childish' }
                    ]}
                  />
                </div>
              </div>

              <ClothingMetricsTable importedModels={importedModels} clothingTableSizeConfig={clothingTableSizeConfig} />
            </CollapsableCard>
          )}

          {editorSettings.final_client_readonly && (
            <React.Fragment>
              <div className='alert alert-warning'>
                <span>{Translate('error.project-cannot-be-changed')}</span>
                <div className='d-inline-flex d-print-none'>
                  <SplitButton
                    marginLeft
                    title={Translate('actions.generate-invoice')}
                    size='sm'
                    color='success'
                    icon={IconsCatalog.invoiceDollar}
                    handleClick={() => {
                      window.open(`/order/invoice/${projectAccessInfo.uuid}`);
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          {!editorSettings.final_client_readonly && (
            <div className='row'>
              <div className='col'>
                <SyncStatusBar isSynching={!isSynched} />
              </div>
            </div>
          )}

          <div className='row'>
            <div className='col'>
              {!editorSettings.final_client_readonly && page === 'pedido' && (
                <CollapsableCard
                  startWithClosedPanel
                  header={!isEditingOrder ? Translate('actions.do-register') : Translate('actions.edit')}
                  id='view-project-client-form'
                >
                  <section data-tour='step-1'>
                    <MainFormEditor
                      order={order}
                      setOrder={setOrder}
                      sublists={sublists}
                      onAddOrder={handleOnAddOrder}
                      importedModels={importedModels}
                      targetSublistIndex={targetSublistIndex}
                      setTargetSublistIndex={setTargetSublistIndex}
                      isCompanyEditor={false}
                      renderAsStepper
                    />
                  </section>
                </CollapsableCard>
              )}

              <section data-tour='step-2'>
                <Suspense fallback={<Spinner />}>{pageCenter}</Suspense>
              </section>
            </div>
          </div>

          {!editorSettings.final_client_readonly && page === 'pedido' && (
            <div className='row mb-3'>
              <div className='col d-flex justify-content-center'>
                <span data-tour='step-3'>
                  <SplitButton
                    size='lg'
                    color='success'
                    title={Translate('labels.authorize-production')}
                    icon={IconsCatalog.check}
                    handleClick={() => {
                      setShowConfirmationModal(true);
                    }}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      )}

      <Footer />
    </React.Fragment>
  );
}

export default function ViewProjectClient() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <TourProvider
        steps={[]}
        showBadge={false}
        beforeClose={() => {
          disableTourGuideShowAgain();
        }}
      >
        <Render />
      </TourProvider>
    </QueryClientProvider>
  );
}
