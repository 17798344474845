import React, { useCallback, type SetStateAction, useMemo, useState } from 'react';
import { generateInitialOrder } from '../../helper/orderHelper';
import { type Order } from '../../../../../../types/OrderTypes';
import { type ClothingModelType } from '../../../../../../types/ClothingModelType';
import { type SublistType } from '../../../../../../types/SublistTypes';
import { toast } from 'react-hot-toast-promise';

import {
  changeGenderAndResetClotheSizes
} from '../../services/orderFormService';

import {
  changeClothingData,
  clearClothingData,
  type ChangeClothingDataType,
  validateOrderBeforeAdd
} from '../../../services/orderService';
import { findSublistIndexBySublistId } from '../../../services/sublistService';
import { useAppTranslation } from '../../../../../../contexts/TranslationContext';
import DefaultListEditor from './components/DefaultListEditor';
import FinalClientStepperListEditor from './components/FinalClientStepperListEditor';
import { getServerErrorMessageFromResponse } from '../../../../../../utils/helper';
import { addOrderToSublist } from '../../../api/addOrders';
import { useHttpRequest } from '../../../../../../contexts/HttpRequestContext';
import { editOrderFromSublist } from '../../../api/editOrder';

export type MainFormEditorType = {
  order: Order;
  sublists: SublistType[];
  onAddOrder?: (order: Order) => void;
  onEditOrder?: (order: Order) => void;
  setOrder: React.Dispatch<SetStateAction<Order>>;
  importedModels: ClothingModelType[];
  targetSublistIndex: number;
  isCompanyEditor: boolean;
  setTargetSublistIndex: React.Dispatch<SetStateAction<number>>;
  renderAsStepper?: boolean;
  isInsideModal?: boolean;
};

export default function MainFormEditor({
  order,
  setOrder,
  onAddOrder,
  onEditOrder,
  sublists,
  importedModels,
  targetSublistIndex,
  setTargetSublistIndex,
  isCompanyEditor,
  renderAsStepper,
  isInsideModal
}: MainFormEditorType) {
  const { Translate } = useAppTranslation();
  const { httpConnection } = useHttpRequest();

  const [disabled, setDisabled] = useState<boolean>(false);

  const urlToken = useMemo<string | null>(() => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('t');
  }, []);

  const finalClientAccessToken = useMemo(() => {
    return (!isCompanyEditor && urlToken) ? urlToken : undefined;
  }, [isCompanyEditor, urlToken]);

  const handleChangeGender = useCallback(
    (gender: Order['gender']) => {
      const updatedOrder = changeGenderAndResetClotheSizes({ order, gender });
      setOrder(updatedOrder);
    },
    [order, setOrder]
  );

  const handleChangeClothingData = useCallback(
    ({ value, field, clothingIndex }: ChangeClothingDataType) => {
      const updatedSublists = changeClothingData({ value, field, clothingIndex }, order);
      setOrder(updatedSublists);
    },
    [order, setOrder]
  );

  const handleClearClothingData = useCallback(
    (clothingIndex: number) => {
      const updatedSublists = clearClothingData(clothingIndex, order);
      setOrder(updatedSublists);
    },
    [order, setOrder]
  );

  const handleClearForm = useCallback(() => {
    setOrder(generateInitialOrder(importedModels));
  }, [importedModels, setOrder]);

  const handleAddOrder = useCallback(() => {
    try {
      if (!onAddOrder) return;
      validateOrderBeforeAdd(order, Translate);

      const targetSublist = sublists[targetSublistIndex];

      if (!targetSublist.id) return;

      setDisabled(true);

      const newOrderPromise = addOrderToSublist({
        httpConnection,
        order: { ...order, sublist_id: targetSublist.id },
        projectId: targetSublist.project_id!,
        finalClientAccessToken
      });

      toast.promise(newOrderPromise, {
        loading: Translate('progress.loading'),
        success: (newOrder) => {
          onAddOrder(newOrder);
          handleClearForm();

          return Translate('toast.order-added');
        },
        error: getServerErrorMessageFromResponse,
        finally: () => {
          setDisabled(false);
        }
      });
    } catch (err) {
      const error = err as Error;
      toast.error(error.message);
    }
  }, [onAddOrder, order, Translate, sublists, targetSublistIndex, httpConnection, finalClientAccessToken, handleClearForm]);

  const handleEditOrder = useCallback(async () => {
    if (!order.id || !onEditOrder) return;

    const targetSublist = sublists[targetSublistIndex];

    if (!targetSublist.id) return;

    setDisabled(true);

    const orderEditPromise = editOrderFromSublist({
      httpConnection,
      order,
      projectId: targetSublist.project_id!,
      finalClientAccessToken
    });

    toast.promise(orderEditPromise, {
      loading: Translate('progress.loading'),
      success: (updatedOrder) => {
        onEditOrder(updatedOrder);
        setOrder(generateInitialOrder(importedModels));
        return Translate('toast.editing-saved');
      },
      error: getServerErrorMessageFromResponse,
      finally: () => {
        setDisabled(false);
      }
    });
  }, [Translate, finalClientAccessToken, httpConnection, importedModels, onEditOrder, order, setOrder, sublists, targetSublistIndex]);

  const handleCancelEdit = useCallback(() => {
    setOrder(generateInitialOrder(importedModels));
  }, [importedModels, setOrder]);

  const handleFindSublistIndex = useCallback((sublist_id: number | undefined): number => {
    return findSublistIndexBySublistId(sublists, sublist_id);
  }, [sublists]);

  /**
   * if resolution width is less them 1600
   * the ClothingInputData will have no enough space to display correctly in company editor
   * this code adjusts and put side by side if has space
   * or one input by line in lower resolutions
   */
  const dynamicLargeClass = useMemo(() => {
    if (isCompanyEditor && window.screen.width < 1600) return 'col-lg-6';
    return 'col-lg-12 col-xl-6';
  }, [isCompanyEditor]);

  return (
    <React.Fragment>
      {
        !renderAsStepper && (
          <DefaultListEditor
            disabled={disabled}
            dynamicLargeClass={dynamicLargeClass}
            handleAddOrder={handleAddOrder}
            handleCancelEdit={handleCancelEdit}
            handleChangeClothingData={handleChangeClothingData}
            handleChangeGender={handleChangeGender}
            handleClearClothingData={handleClearClothingData}
            handleClearForm={handleClearForm}
            handleEditOrder={handleEditOrder}
            handleFindSublistIndex={handleFindSublistIndex}
            importedModels={importedModels}
            isCompanyEditor={isCompanyEditor}
            order={order}
            setOrder={setOrder}
            setTargetSublistIndex={setTargetSublistIndex}
            sublists={sublists}
            targetSublistIndex={targetSublistIndex}
          />
        )
      }

      {
        renderAsStepper && (
          <FinalClientStepperListEditor
            handleAddOrder={handleAddOrder}
            handleCancelEdit={handleCancelEdit}
            handleChangeClothingData={handleChangeClothingData}
            handleChangeGender={handleChangeGender}
            handleClearClothingData={handleClearClothingData}
            handleClearForm={handleClearForm}
            handleEditOrder={handleEditOrder}
            handleFindSublistIndex={handleFindSublistIndex}
            importedModels={importedModels}
            order={order}
            setOrder={setOrder}
            setTargetSublistIndex={setTargetSublistIndex}
            sublists={sublists}
            targetSublistIndex={targetSublistIndex}
            stretched={isInsideModal}
          />
        )
      }
    </React.Fragment>
  );
}
