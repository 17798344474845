import { useFormContext } from 'react-hook-form';
import { WrapperInput, WrapperInputs } from '../../style';
import _ from 'lodash';
import { useMemo } from 'react';

type InputsListProps = {
  rawMaterialId: number;
  productId: number;
  productUnits?: Array<{
    id: number;
    product_id: number;
    price: string;
    width: number;
    length: number | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
  }>;
  typeMeter: 'unit' | 'meter' | 'liter';
};

export function InputsList({ rawMaterialId, productId, productUnits, typeMeter }: InputsListProps) {
  const { register, setValue, watch } = useFormContext();
  const rawMaterialIdKey = `${rawMaterialId}.${productId}`;
  const widthRawMaterial = `${rawMaterialIdKey}.width`;
  const heightRawMaterial = `${rawMaterialIdKey}.height`;

  const maxWidthValue = useMemo(() => {
    return (
      productUnits?.reduce((acc, item) => {
        return acc + item.width;
      }, 0) ?? 0
    );
  }, [productUnits]);

  const maxHeightValue = useMemo(() => {
    return (
      productUnits?.reduce((acc, item) => {
        return acc + (item.length ?? 0);
      }, 0) ?? 0
    );
  }, [productUnits]);

  if (_.isNil(watch(rawMaterialIdKey))) {
    return (
      <p className='d-flex align-items-center text-success mt-2 mb-0'>
        <i className='fas fa-check mr-2' />
        <b>Matéria prima inserida e descontada do estoque</b>
      </p>
    );
  }

  return (
    <WrapperInputs>
      <WrapperInput>
        <label htmlFor={`width-${productId}`}>{typeMeter === 'meter' ? 'Largura' : 'Quantidade'}:</label>
        <input
          id={`width-${productId}`}
          type='number'
          className={`form-control ${_.isEqual(maxWidthValue, 0) ? 'is-invalid' : null}`}
          disabled={_.isEqual(maxWidthValue, 0)}
          min={0}
          max={maxWidthValue}
          defaultValue={0}
          {...register(widthRawMaterial)}
          onChange={e => {
            if (+e.target.value < 0 || _.isEmpty(e.target.value)) {
              setValue(widthRawMaterial, 0);
              return;
            }

            if (+e.target.value > maxWidthValue) {
              setValue(widthRawMaterial, maxWidthValue);
              return;
            }

            setValue(widthRawMaterial, e.target.value);
          }}
        />
      </WrapperInput>

      {typeMeter === 'meter' ? (
        <WrapperInput>
          <label htmlFor={`height-${productId}`}>Estoque: </label>
          <input
            id={`height-${productId}`}
            type='number'
            min={0}
            max={maxHeightValue}
            className={`form-control ${_.isEqual(_.defaultTo(maxHeightValue, 0), 0) ? 'is-invalid' : null}`}
            disabled={_.isEqual(_.defaultTo(maxHeightValue, 0), 0)}
            defaultValue={0}
            {...register(heightRawMaterial)}
            onChange={e => {
              if (+e.target.value < 0 || _.isEmpty(e.target.value)) {
                setValue(heightRawMaterial, 0);
                return;
              }

              if (+e.target.value > maxHeightValue) {
                setValue(heightRawMaterial, maxHeightValue);
                return;
              }

              setValue(heightRawMaterial, e.target.value);
            }}
          />
        </WrapperInput>
      ) : null}
    </WrapperInputs>
  );
}
