import _ from 'lodash';
import { useState, Fragment } from 'react';
import { useQueries } from 'react-query';

import { Container, ContentCard } from './style';
import { getAllInventories } from './service';
import BasicCard from '../../../../components/Cards/BasicCard';
import DatePickerInput from '../../../../components/Forms/DatePickerInput';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import { getEntities } from '../Entities/api';

const options = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  refetchOnReconnect: false,
  refetchInterval: false as false,
  refetchIntervalInBackground: false
};

export function Seamstresses() {
  const [selectSeamstress, setSelectSeamstress] = useState<string>('');
  const [date, setDate] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date()
  });
  const { Translate } = useAppTranslation();
  const [inventory, entities] = useQueries([
    {
      queryKey: 'inventory',
      queryFn: async () => {
        return await getAllInventories.execute({
          startDate: date.startDate,
          endDate: date.endDate,
          selectSeamstress
        });
      },
      ...options
    },
    {
      queryKey: 'entities',
      queryFn: async () => {
        return await getEntities.execute({ type: 'seamstress' });
      },
      ...options
    }
  ]);

  return (
    <Container>
      <BasicCard title='Costureiras' hideOnPrint>
        <div className='row'>
          <div className='col-3 d-flex align-items-end'>
            <select
              className='form-control mb-3'
              onChange={e => {
                setSelectSeamstress(e.target.value);
              }}
            >
              <option value=''>Selecione uma costureira</option>
              {entities.data?.map(entity => (
                <option key={entity.id} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </select>
          </div>

          <div className='col-2'>
            <DatePickerInput
              label={Translate('labels.start-date')}
              startDate={date.startDate}
              handleDateChange={selectedDate => {
                setDate({ ...date, startDate: selectedDate });
              }}
            />
          </div>

          <div className='col-2'>
            <DatePickerInput
              label={Translate('labels.end-date')}
              startDate={date.endDate}
              handleDateChange={selectedDate => {
                setDate({ ...date, endDate: selectedDate });
              }}
            />
          </div>

          <div className='col d-flex align-items-end'>
            <button
              type='button'
              className='btn btn-primary mb-3'
              onClick={() => {
                inventory.refetch();
              }}
            >
              {inventory.isFetching ? <i className='fas fa-spinner fa-spin'></i> : <i className='fas fa-search'></i>}
            </button>
          </div>
        </div>
      </BasicCard>

      <ContentCard>
        {_.isEmpty(inventory.data) ? (
          <p>Nenhum registro encontrado</p>
        ) : (
          <table className='table table-borderless'>
            <thead>
              <tr>
                <th scope='col'>
                  <b>Costureiras</b>
                </th>
                <th scope='col'>
                  <b>Lista</b>
                </th>
                <th scope='col'>
                  <b>Quantidade de peças</b>
                </th>
                <th scope='col'>
                  <b>Valor unitário</b>
                </th>
                <th scope='col'>
                  <b>Sublista</b>
                </th>
                <th scope='col'>
                  <b>Valor a receber</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {inventory.data?.map(item => {
                return item?.factions?.map(faction => {
                  return (
                    <Fragment key={faction?.id}>
                      <tr>
                        <td>{item?.name}</td>
                        <td>{faction?.project_title}</td>
                        <td>{faction?.quantity}</td>
                        <td>
                          {new Intl.NumberFormat('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(Number(faction?.price))}
                        </td>
                        <td>{faction?.sublist_title}</td>
                        <td>
                          {new Intl.NumberFormat('pt-br', {
                            style: 'currency',
                            currency: 'BRL'
                          }).format(faction?.total)}
                        </td>
                      </tr>
                    </Fragment>
                  );
                });
              })}
            </tbody>
          </table>
        )}
      </ContentCard>
    </Container>
  );
}
