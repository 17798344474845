import _ from 'lodash';
import { generatePathRegisterFile } from '../../../../../../../../helpers/general';
import { type SublistType } from '../../../../../../../../types/SublistTypes';

type RawMaterialTableProps = {
  sublist: SublistType;
};

export function RawMaterialTable({ sublist }: RawMaterialTableProps) {
  return (
    <table className='table'>
      <thead>
        <tr>
          <th>Matéria</th>
          <th>Preço</th>
          <th>Quantidade</th>
          <th>Total</th>
        </tr>
      </thead>

      <tbody>
        {sublist?.inventory?.map(inventory => {
          return (
            <tr key={sublist.id}>
              <td>
                <div className='d-flex'>
                  <img
                    src={generatePathRegisterFile({ filename: inventory?.product?.photo })}
                    alt=''
                    srcSet=''
                    width={50}
                    height={50}
                  />

                  <div className='d-flex flex-column ml-3'>
                    <span>{inventory?.product?.title}</span>
                    <span>{inventory?.product?.description}</span>
                  </div>
                </div>
              </td>
              <td>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(Number(inventory?.product?.product_unit?.price))}
              </td>
              <td>
                {Number(inventory?.width)} {inventory?.height ? ` x ${Number(inventory?.height)}` : null}
              </td>
              <td>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(
                  Number(inventory?.width) * Number(inventory?.height ?? 1) * Number(inventory?.product?.product_unit?.price)
                )}
              </td>
            </tr>
          );
        })}
      </tbody>

      <tfoot>
        <tr>
          <td colSpan={3}>Total</td>
          <td>
            <b>
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(
                _.sumBy(sublist?.inventory, stock => {
                  const price = Number(stock?.product?.product_unit?.price);
                  return price * Number(stock?.width) * Number(stock?.height ?? 1);
                })
              )}
            </b>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
