import _ from 'lodash';
import { useState } from 'react';
import { useQueries } from 'react-query';
import { format } from 'date-fns';

import { Container, ContentCard } from './style';
import { getAllInventories } from './service';
import BasicCard from '../../../../components/Cards/BasicCard';
import DatePickerInput from '../../../../components/Forms/DatePickerInput';
import { useAppTranslation } from '../../../../contexts/TranslationContext';
import { getEntities } from '../Entities/api';

const options = {
  refetchOnWindowFocus: false,
  refetchOnMount: true,
  refetchOnReconnect: false,
  refetchInterval: undefined,
  refetchIntervalInBackground: false
};

export function Register() {
  const [supplier, setSupplier] = useState<string>('');
  const [date, setDate] = useState({
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate: new Date()
  });
  const { Translate } = useAppTranslation();
  const [register, entities] = useQueries([
    {
      queryKey: 'getAllRegister',
      queryFn: async () => {
        return await getAllInventories.execute({ startDate: date.startDate, endDate: date.endDate, supplier });
      },
      ...options
    },
    {
      queryKey: 'entities',
      queryFn: async () => {
        return await getEntities.execute({ type: 'supplier' });
      },
      ...options
    }
  ]);

  return (
    <Container>
      <BasicCard title='Estoque' hideOnPrint>
        <div className='row'>
          <div className='col-3 d-flex align-items-end'>
            <select
              className='form-control mb-3'
              onChange={e => {
                setSupplier(e.target.value);
              }}
            >
              <option value=''>Selecione um fornecedor</option>
              {entities.data?.map(entity => (
                <option key={entity.id} value={entity.id}>
                  {entity.name}
                </option>
              ))}
            </select>
          </div>

          <div className='col'>
            <DatePickerInput
              label={Translate('labels.start-date')}
              startDate={date.startDate}
              handleDateChange={selectedDate => {
                setDate({ ...date, startDate: selectedDate });
              }}
            />
          </div>

          <div className='col'>
            <DatePickerInput
              label={Translate('labels.end-date')}
              startDate={date.endDate}
              handleDateChange={selectedDate => {
                setDate({ ...date, endDate: selectedDate });
              }}
            />
          </div>

          <div className='col d-flex align-items-end'>
            <button
              type='button'
              className='btn btn-primary mb-3'
              onClick={() => {
                register.refetch();
              }}
            >
              {register.isFetching ? <i className='fas fa-spinner fa-spin'></i> : <i className='fas fa-search'></i>}
            </button>
          </div>
        </div>
      </BasicCard>

      <ContentCard>
        {_.isEmpty(register.data) ? (
          <p>Nenhum registro encontrado</p>
        ) : (
          <table className='table table-borderless'>
            <thead>
              <tr>
                <th scope='col'>
                  <b>Fornecedor</b>
                </th>
                <th scope='col'>
                  <b>Matéria prima</b>
                </th>
                <th scope='col'>
                  <b>Estoque</b>
                </th>
                <th scope='col'>
                  <b>Última atualização</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.values(register.data ?? {})?.map((item: any) => {
                return (
                  <tr key={item.id}>
                    <td>{item?.entity?.name}</td>
                    <td>{item?.title}</td>
                    <td>
                      <b className='mr-1'>{item?.total}</b>
                      {item?.type === 'increase' ? (
                        <i className='fas fa-arrow-up text-success'></i>
                      ) : (
                        <i className='fas fa-arrow-down text-danger'></i>
                      )}
                    </td>
                    <td>{format(new Date(item?.created_at), 'dd-MM-yyyy')}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </ContentCard>
    </Container>
  );
}
