import { type Order, type ClothingProductionData } from '../../../../../../../../../types/OrderTypes';
import { type ClothingModelType } from '../../../../../../../../../types/ClothingModelType';

type ProductionData = {
  gender: Order['gender'];
  modelsProductionData: ClothingProductionData[][];
}

type GenerateOrdersFromClothingProductionDataType = {
  productionData: ProductionData[];
  importedModels: ClothingModelType[];
}

/**
 * Method to fill missing clothes in production data. Eg. If we have 2 models, only 1 clothe will have filled data, so this method will generate the missing clothing production data.
 * @param importedModels models used in this project
 * @param sigleProductionData informations about a single clothe
 * @returns filled clothing production data array following the models count.
 */
const generateSingleClothingProductionDataBasedInImportedModelsCount = (importedModels: ClothingModelType[], sigleProductionData: ClothingProductionData): ClothingProductionData[] => {
  const filledClothingProductionData = importedModels.map((currentModel): ClothingProductionData => {
    if (currentModel.id === sigleProductionData.modelId) {
      return sigleProductionData;
    }

    return { modelId: currentModel.id, sizeIndex: -1, quantity: 0 };
  });

  return filledClothingProductionData;
};

export const generateOrdersFromClothingProductionData = ({
  productionData,
  importedModels
}: GenerateOrdersFromClothingProductionDataType): Order[] => {
  const gendersProductionData = productionData.map(model => {
    return model.modelsProductionData.map((data) => {
      return data.filter(information => information.quantity);
    });
  });

  const orders: Order[] = [];

  gendersProductionData.forEach((gender, index): void => {
    gender.forEach(filledSizes => {
      filledSizes.forEach(currentProductionData => {
        const order: Order = {
          name: '',
          nickname: '',
          number: '',
          bloodType: '',
          gender: productionData[index].gender,
          clothes: [...generateSingleClothingProductionDataBasedInImportedModelsCount(importedModels, currentProductionData)],
          totalValue: 0
        };

        orders.push(order);
      });
    });
  });

  return orders;
};
