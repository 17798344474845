import _ from 'lodash';
import { type AxiosAdapters } from '../../../../../helpers/adapters/http/AxiosAdapters';

type FormDataEntries = Record<string, string | Blob> & {
  products?: Array<{
    photo: FileList;
    title: string;
    description: string;
    meter: string;
    value: string;
    width: string;
  }>;
  factions?: Array<{ id: number; price: number }>;
};

export class CreateEntity {
  constructor(private readonly http: AxiosAdapters) {}

  async execute(data: FormDataEntries): Promise<void> {
    const formData = new FormData();

    Object.keys(data).forEach(value => {
      if (value === 'photo') {
        const photoValue = data[value] as unknown as FileList;
        formData.append('photo', _.first(photoValue) as unknown as File);
        return;
      }

      if (['products', 'factions'].includes(value)) {
        return;
      }

      formData.append(value, data[value]);
    });

    if (data.products) {
      data.products.forEach((rawMaterial, index) => {
        Object.keys(rawMaterial).forEach(key => {
          const value = key as keyof typeof rawMaterial;
          if (value === 'photo') {
            const photoValue = rawMaterial[value] as unknown as FileList;
            formData.append(`products[${index}][${value}]`, _.first(photoValue) as unknown as File);
            return;
          }
          formData.append(`products[${index}][${value}]`, rawMaterial[value]);
        });
      });
    }

    if (data.factions) {
      data.factions.forEach((model, index) => {
        if (data.factions) {
          formData.append(`factions[${index}][id]`, model.id.toString());
          formData.append(`factions[${index}][price]`, model.price.toString());
        }
      });
    }

    await this.http.post('/entity', formData);
  }
}
