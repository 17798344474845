import React from 'react';
import { type BasicTextInputProps } from '../BasicTextInput';

type TextInputPrependedTextType = BasicTextInputProps & {
  prependedText: string;
};

export default function TextInputPrependedText({
  id,
  tabIndex,
  type = 'text',
  value,
  onChange,
  prependedText,
  disabled
}: TextInputPrependedTextType) {
  return (
    <div className='input-group'>
      <div className='input-group-prepend'>
        <span className='input-group-text' id={id}>
          {prependedText}
        </span>
      </div>
      <input
        disabled={disabled}
        type={type}
        tabIndex={tabIndex}
        className='form-control'
        value={value}
        onChange={onChange}
      />
    </div>
  );
}
